<template>
  <div class="wap-box">
        <to-history :prop-message="title"></to-history> 
    <div class="count flex">
        <h4>目标分析</h4>
        <div>
            <a-radio-group v-model="choose" @change="chooseChange">
                <a-radio-button v-for="(item, index) in chooseArr" :key="index" :value="item.value">
                    {{ item.label }}
                </a-radio-button>
            </a-radio-group>
        </div>
    </div>
    <div class="flex count" style="margin:20px 0;align-items: center">
        <a-select v-model="allValue" @change="timeChange(allValue, 1)">
            <a-select-option
                v-for="(item, index) in timeArr"
                :key="index"
                :value="item.value"
                >{{ item.label }}</a-select-option
            >
        </a-select>
        <a-date-picker class="head_date" :inputReadOnly="true" v-model="timeStart" @change="onChange()"/>至<a-date-picker class="head_date" v-model="timeEnd" @change="onChange()"/>
    </div>
    <div class="flex">
      <a-radio-group v-model="targettype" @change="chooseChange" style="margin-right:30px">
        <a-radio-button v-for="(item, index) in targetArr" :key="index" :value="item.key">
          {{ item.value }}
        </a-radio-button>
      </a-radio-group>

      <a-select v-if="targettype == 3" v-model="executor" placeholder="请选团队" style="width: 200px"
        show-search @change="chooseChange">
        <a-select-option v-for="(item, index) in clientList" :key="index" :value="item.userid">
          {{ item.name }}
        </a-select-option>
      </a-select>
      <a-select v-if="targettype == 2" v-model="teamor" placeholder="请选择执行人" style="width: 200px"
        show-search @change="chooseChange">
        <a-select-option v-for="(item, index) in teamList" :key="index" :value="item.did">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </div>
      
   
    <div class="pannel">
      <div class="flex countTitle">
        <!-- <h4 class="f_w">目标数据</h4> -->
      </div>
      <div>
        <!-- <div id="newcustomers" style="width: 100%; height: 280px"></div> -->
        <!-- <div id="signlistNum" style="width: 100%; height: 280px"></div> -->
        <div id="signlistAmount" style="width: 100%; height: 280px"></div>
        <!-- <div id="paymentNum" style="width: 100%; height: 280px"></div> -->
        <div id="paymentAmount" style="width: 100%; height: 280px"></div>
        <!--<div id="orderNewPie" style="width:100%;height:280px;"></div> -->
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { requestXml } from "../../../assets/js/request";

let targetArr = [
  {
    key: 2,
    value: "团体",
  },
  {
    key: 3,
    value: "个人",
  },
];
var moment = require("moment");
export default {
  name: "envyPie",
  props: {
    voltage: Array,
  },
  data() {
    return {
      executor: "", //执行人
      clientList: [], //执行人列表

      teamor: "", //团体id
      teamList: [], //团体列表
      targetArr,
      targettype: 3, //完成主体类型1公司,2团体,3个人
      start: "",
      end: "",

      allValue: 3,
      timeArr: [
        // { label: "今日", value: 1 },
        { label: "本周", value: 2 },
        { label: "本月", value: 3 },
        { label: "全年", value: 4 },
      ],
      timeStart: null,
      timeEnd: null,
      choose: "W",
      chooseArr: [
        // { label: "按日", value: "D" },
        { label: "按周", value: "W" },
        { label: "按月", value: "M" },
        { label: "按年", value: "Y" },
      ],
      title:''
    };
  },
    mounted: function() {
        this.start = moment().startOf('month').format('x');
        this.end =  moment().endOf('month').format('x');
        // 获取客户分析数据
        this.getPannel();
        // 获取执行人列表
        this.getClientList();
    },
    methods: {
        // 获取 简化成员列表
    getClientList() {
      requestXml("/scrm/Staff/getMinList", "POST", (res) => {
        this.clientList = res;
      });
      requestXml("/scrm/Target/department", "POST", (res) => {
        console.log("teamListteamList");
        this.teamor = res[0].did;
        this.teamList = res;
      });
    },
        // 设置开始时间0:0:0 结束时间23:59:59
        setTime(time,type){
            if(type == 1){
                time.setHours(0);
                time.setMinutes(0);
                time.setSeconds(0);
            }else{
                time.setHours(23);
                time.setMinutes(59);
                time.setSeconds(59);
            }
            return time.getTime();
        },
        
        timeChange(val){
            if(val == 1){
                this.start = moment().startOf('day').format('x');
                this.end =  moment().endOf('day').format('x');
            }else if(val == 2){
                this.start = moment().startOf('week').add(1,'day').format('x');
                this.end =  moment().endOf('week').add(1,'day').format('x');
            }else if(val == 3){
                this.start = moment().startOf('month').format('x');
                this.end =  moment().endOf('month').format('x');
            }else{
                this.start = moment().startOf('year').format('x');
                this.end =  moment().endOf('year').format('x');
            }
            this.timeStart='';
            this.timeEnd='';
            this.getPannel();
        },
        onChange() {
            if(this.timeStart){
                this.start = moment(this.timeStart).startOf('day').format("x");
            }else{
                this.start='';
            }
            if(this.timeEnd){
                this.end = moment(this.timeEnd).endOf('day').format("x");
            }else{
                this.end='';
            }
            this.getPannel();
            
        },
        chooseChange(){
            this.getPannel();
        },
    getPannel() {
      let postData = {
        start: this.start,
        end: this.end,
        timetype: this.choose,
        targettype: this.targettype,
      };

      if (this.targettype == 3) {
        postData.staff_id = this.executor;
      }
      if (this.targettype == 2) {
        postData.staff_id = this.teamor;
      }
      requestXml(
        "/scrm/Statistics/getGarget",
        "POST",
        (res) => {
          // 目标
          // console.log(res);
          // let newcustomersArr = [];
          // (newcustomersArr[0] = {
          //     type: "bar",
          //     data: res.newcustomers[1],
          // }),
          //     (newcustomersArr[1] = {
          //         type: "bar",
          //         data: res.newcustomers1[1],
          //     }),
          //     console.log(newcustomersArr);
          // this.drawBarChart(
          //     "新客数目标",
          //     res.newcustomers[0],
          //     newcustomersArr,
          //     "newcustomers"
          // );

          // let signlistNumArr = [];
          // (signlistNumArr[0] = {
          //     type: "bar",
          //     data: res.signlist_num[1],
          // }),
          //     (signlistNumArr[1] = {
          //         type: "bar",
          //         data: res.signlist_num1[1],
          //     }),
          //     this.drawBarChart(
          //         "订单数目标",
          //         res.newcustomers[0],
          //         signlistNumArr,
          //         "signlistNum"
          //     );

          let signlistAmountArr = [];
          (signlistAmountArr[0] = {
            type: "bar",
            data: res.signlist_amount[1],
          }),
            (signlistAmountArr[1] = {
              type: "bar",
              data: res.signlist_amount1[1],
            }),
            this.drawBarChart(
              "开票金额目标",
              res.signlist_amount[0],
              signlistAmountArr,
              "signlistAmount"
            );

          // let paymentNumArr = [];
          // (paymentNumArr[0] = {
          //     type: "bar",
          //     data: res.payment_num[1],
          // }),
          //     (paymentNumArr[1] = {
          //         type: "bar",
          //         data: res.signlist_num1[1],
          //     }),
          //     this.drawBarChart(
          //         "收款数目标",
          //         res.newcustomers[0],
          //         paymentNumArr,
          //         "paymentNum"
          //     );

          let paymentAmountArr = [];
          (paymentAmountArr[0] = {
            type: "bar",
            data: res.payment_amount[1],
          }),
            (paymentAmountArr[1] = {
              type: "bar",
              data: res.payment_amount1[1],
            }),
            this.drawBarChart(
              "收款金额目标",
              res.payment_amount[0],
              paymentAmountArr,
              "paymentAmount"
            );
          // let orderAll = Number(res.all.num);
          // let orderNewArr = [];
          // orderNewArr[0] = {"name": "原有订单" ,"value": orderAll - Number(res.new.num)};
          // orderNewArr[1] = {"name": "新增订单" ,"value": res.new.num};
          // this.drawPieChart("新增订单数据",orderNewArr,"orderNewPie");

          // let corpUnassignedArr = [];
          // corpUnassignedArr[0] = {"name": "已分配客户数" ,"value": corAll - Number(res.corp.unassigned)};
          // corpUnassignedArr[1] = {"name": "未分配客户数" ,"value": res.corp.unassigned};
          // this.drawPieChart("未分配客户数据",corpUnassignedArr,"corpUnassignedPie");

          // let corpOrderokArr = [];
          // corpOrderokArr[0] = {"name": "未成交客户数" ,"value": corAll - Number(res.corp.order_ok)};
          // corpOrderokArr[1] = {"name": "成交客户数" ,"value": res.corp.order_ok};
          // this.drawPieChart("成交客户数据",corpOrderokArr,"corpOrderokPie");

          // this.drawPieChart("客户来源分类",res.corp.origin_type,"corpOriginPie");
          // for(let i=0;i<res.corp.follow_type.length;i++){
          //     res.corp.follow_type[i].name = this.followArr[Number(res.corp.follow_type[i].name)-1]
          // }
          // this.drawPieChart("跟进记录分类",res.corp.follow_type,"corpFollowPie");
        },
        postData
      );
    },
    drawBarChart(name, xData, allData, ele) {
      var myChart = echarts.init(document.getElementById(ele));
      // 绘制图表
      myChart.setOption({
        title: {
          text: name,
        },
        tooltip: {},
        xAxis: {
          data: xData,
        },
        yAxis: {},
        series: allData,
      });
    },
  },
};
</script>
<style scoped>

</style>
